body {
    background-color: lightgray;
}

@import "~bootstrap/scss/bootstrap";
@import '~@fortawesome/fontawesome-free/css/all.css';
@import 'bootstrap-datepicker.standalone.min.css';

#header{
    padding-bottom: 30px;
    border-bottom: 30px solid #46abdf;
    margin-bottom: 30px;
    position: relative;
}

.navigation{
    position: absolute;
    bottom: -30px;
    left: 15px;
}

.navigation a{
    color: #ffffff;
    background-color: #46abdf;
    padding: 10px;
    margin-right: 10px;
    -webkit-border-top-left-radius: 10px;
    -webkit-border-top-right-radius: 10px;
    -moz-border-radius-topleft: 10px;
    -moz-border-radius-topright: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

}

.vmiddle {
    display:flex;
    align-items:center;
}
.navigation .dropdown > button{
    height: 37px;
    margin-top: -8px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: #ffffff;
    background-color: #46abdf;
    border: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding: 10px;

}

.navigation .dropdown-item{
    color: #ffffff;
    background-color: #46abdf;

    padding: 0;
    margin: 0;

}

.dropdown-menu{
    border-top: none;
    padding: 0;


}

.navigation .show > .btn-secondary.dropdown-toggle{
    color: #ffffff;
    background-color: #46abdf;

}
.navigation .dropdown-item a{
    padding: 10px;
    margin: 0;
    border-radius: 0;
    display: block;

}


.itemlist td{
    padding: 10px;
}

.filter{
    margin: 0 0 20px 0;
}

.filter .form-group{
    display: inline-block;
    margin: 0 10px 0 0;
}

#random_password{
    border: 1px solid #222222;
    background-color: #00bb00;
    color: #dddddd;
    display: inline;
    padding: 10px;
}

#clickcopy{
    display: inline;
}

.importfile_delete:before{
    font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f00d";
}

.icon::before {
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
}

.fixed {
    position: fixed;
    top:0;
    left:auto;
    right: auto;
    width: 1140px;
    padding: 0 15px 0 15px;
    background-color: #ffffff;
    border-bottom: 1px solid #222222;
    z-index: 10;
    margin-left: -15px;

}
